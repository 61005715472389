@mixin flex($justify-content, $align-items) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin generate-space($prefix, $property) {
  $sizes: [0, .2, .3, .5, .7, 1, 1.2, 1.5, 2, 2.5, 3];
  $spacing: 1rem;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);
    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size !important;
    }
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet-and-mobile {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop-small {
  @media (min-width: #{$desktop-width}) and (max-width: #{$big-desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-big {
  @media (min-width: #{$big-desktop-width - 1px}) {
    @content;
  }
}
