html {
  font: 400 10px 'Arial', sans-serif;
  scroll-behavior: smooth;
}

body {
  position: relative;
  @include flex(flex-start, center);
  flex-direction: column;
  font-size: 1.6rem;
  background: $light-grey;
}

blockquote {
  position: relative;
  display: block;
  margin: 5rem 0 5rem;
  padding: 0 2rem;


  p {
    color: $plum;
    font-size: 1.65rem;
    line-height: 1.4;
    font-family: $font-ptSerif;

    a {
      font-size: inherit;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0.2rem;
    height: 100%;
    background: $black;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

ol {

  li {
    position: relative;
    color: $plum;
    font-family: $font-ptSerif;
    line-height: 1.7;
    counter-increment: item;
    padding-left: 8rem;

    &:before {
      content: counter(item) ". ";
      position: absolute;
      left: 20px;
      font-family: $font-acumin;
    }

    li {
      counter-reset: item;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@include generate-space(p, padding);
@include generate-space(m, margin);
@include generate-space(pb, padding-bottom);
@include generate-space(mb, margin-bottom);
@include generate-space(pt, padding-top);
@include generate-space(mt, margin-top);
@include generate-space(pr, padding-right);
@include generate-space(mr, margin-right);
@include generate-space(pl, padding-left);
@include generate-space(ml, margin-left);

@include desktop {
  body .desktop-hide {
    display: none;
  }

  .container {
    max-width: 1120px;
  }
}

@include tablet-and-desktop {
  blockquote {
    padding: 0;

    p {
      max-width: 72rem;
      margin: 0 auto;
    }

    &:before,
    &:after {
      width: 0.3rem;
    }
  }

  .container {
    margin: 0 auto;
  }
}

@include tablet {
  body .tablet-hide {
    display: none;
  }

  .container {
    max-width: 70rem;
  }

  blockquote {
    max-width: 50rem;
    margin: 5rem auto;

    p {
      font-size: 2rem;
      padding-right: 5rem;
    }

    &:before {
      left: -60px;
    }
  }

  ol {
    max-width: 50rem;
    padding-left: 10rem;

    li {
      font-size: 2rem;

      &:before {
        left: 0;
      }
    }
  }
}

@include mobile {
  body .mobile-hide {
    display: none;
  }

  .container {
    padding: 0 2rem;
  }
}

@include tablet-and-mobile {
  body .tablet-down-hide {
    display: none;
  }

  .wrapper {
    overflow: hidden
  }

  ol {
    margin: 0 auto;
  }
}

@include desktop {
  .container {
    padding: 0 2%;
  }

  blockquote {

    p {
      font-size: 3.1rem;
    }

    &:before {
      left: 150px;
    }
  }

  ol {
    max-width: 72rem;
    margin: 0 auto;
    padding-left: 150px;

    li {
      font-size: 3.1rem;

      &:before {
        left: 0;
      }
    }
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 138rem;
  }
}

@include desktop-big {
  .container {
    padding: 0;
  }
}
