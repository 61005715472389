.section {
  position: relative;
  width: 100%;
  background: $light-grey;
  z-index: 1;
  overflow: hidden;
  padding: 2rem 0 8rem;
}

.section--first,
.section--second {
  margin-bottom: 3rem;
}

.section__nav {
  @extend .header__nav;
  padding-bottom: 5rem;
}

.section__desc {
  padding: 0 2rem;
}

.section__desc-with-padding {
  padding: 0 2rem 0 8rem;
}

.section__author {
  display: block;
  margin-top: 4rem;
  color: $plum;
  font-size: 1rem;
  font-family: $font-acumin-Semibold;
}

.section__author-img {
  position: absolute;
  bottom: 0;
  right: -15px;
  width: 21rem;
  z-index: -1;
}

.section__header {
  position: relative;
  padding-bottom: 5rem;
}

.section__lang {
  @extend .header__lang;
}

.section__title {
  width: 100%;
  text-align: center;
  color: $white;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  .bold {
    font-family: $font-Poppins;
    letter-spacing: 0.5px;
  }
}

.section__title--second {
  top: 25px;
}

.section__title--third {
  top: 23px;
}

.section__certified {
  @include flex(space-between, flex-start);
}

.section__certified-img {
  margin-right: 3rem;
  margin-left: -2rem;
  width: 5rem;
}

.section__download-report {
  color: $white;
  font-size: 1.6rem;
  line-height: 1.45;
  text-transform: uppercase;
  font-family: $font-Poppins;
  letter-spacing: 1px;
  display: flex;
  padding-bottom: 12rem;
  align-items: center;

  &:before {
    content: url('../images/shapes/plama-12.svg');
    position: absolute;
    width: 28rem;
    height: 28rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  > a {
    color: inherit;
  }
}

.section__woman-with-phone {
  width: 15rem;
}

.section__download {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.section__img-hand-wrapper {
  position: absolute;
  width: 17.7rem;
  left: -85px;

  img {
    width: 17rem;
  }

  &:after {
    content: url('../images/shapes/plama-10.svg');
    position: absolute;
    left: 70px;
    width: 17rem;
    z-index: -1;
  }
}

.section__shape-big-blue {
  position: absolute;
  left: -70px;
  top: 220px;
  width: 25rem;
  z-index: -1;
}

.section__shape-red {
  position: absolute;
  width: 18.8rem;
  left: -30px;
  bottom: 0;
  z-index: -1;
}

.section__shape-turquoise {
  position: absolute;
  width: 29rem;
  right: -130px;
  bottom: 340px;
  z-index: -1;
}

.shape__shape-yellow {
  position: absolute;
  top: 80px;
  left: -90px;
  width: 25rem;
  z-index: -1;
}

.section__shape-blue {
  position: absolute;
  top: 150px;
  left: -25px;
  width: 13rem;
  z-index: -1;
}

.section__img-coffe {
  position: absolute;
  width: 22rem;
  height: auto;
  right: -84px;
  top: -136px;
}

.section--only-img {
  display: block;
  position: relative;
  width: 100%;
  background: transparent;
  overflow: visible;
  padding: 0;
  z-index: 2;
}

@include desktop-big() {
  .section__desc {
    max-width: 72rem;
    margin: 0 auto;
  }

  .section__shape-blue {
    top: 140px;
    left: 95px;
    width: 17rem;
  }

  .section__shape-red {
    width: 45rem;
    bottom: 100px;
  }

  .section__img-coffe {
    width: 50rem;
    top: -350px;
  }

  .quote {
    &:before {
      left: -360px !important;
    }
  }
}

@media screen and (min-width: 1400px) {
  .section__download-report {
    &:before {
      left: 60% !important;
    }
  }
}

@include tablet-and-desktop() {
  .section {
    padding: 4rem 0 14rem;
  }

  .section__desc,
  .section__desc-with-padding {
    padding: 0;
  }

  .section__nav {
    padding: 0 0 3rem;
  }

  .quote {
    position: relative;

    &:before {
      content: url('../images/icons/trojkat-cytat.svg');
      position: absolute;
      top: 10px;
      z-index: 1;
    }
  }

  .shape__shape-yellow {
    top: 120px;
    z-index: -2;
  }

  .section__shape-blue {
    left: 95px;
    width: 17rem;
  }

  .section__author-img {
    bottom: 0;
    right: 60px;
    width: 300px;
  }

  .section__author--artur {
    width: 32rem;
  }

  .section__title {
    top: 25px;
  }

  .section__small-text {
    font-size: 2rem;
  }
}

@include desktop() {
  .section {
    padding: 0 0 18rem;
  }

  .section--first {
    margin-bottom: 8rem;
  }

  .section--second {
    margin-bottom: 5rem;
  }

  .section--fourth {
    .section__desc {
      margin-top: 4rem;
    }
  }

  .section--fifth,
  .section--sixth {
    margin-top: 6rem;
  }

  .section--sixth {
    margin-bottom: 4rem;
  }

  .section--seventh {
    blockquote {
      margin-bottom: 9rem;
    }
  }

  .section--eight {
    blockquote {
      margin: 6rem 0 14rem;
    }
  }

  .section__desc,
  .section__desc-with-padding {
    max-width: 74rem;
    margin: 0 auto;
  }

  .section__download {
    margin-right: 15%;
  }

  .section__download-report {
    font-size: 4rem;
    padding-bottom: 20rem;

    &:before {
      width: 50rem;
      height: 50rem;
      left: 50%;
    }
  }

  .section__woman-with-phone {
    width: 29rem;
  }

  .section__title {
    top: 50px;
  }

  .shape__shape-yellow {
    left: 0;
    width: 50rem;
  }

  .section__shape-blue {
    width: 28rem;
    top: 240px;
  }

  .section__shape-turquoise {
    width: 45rem;
    right: 100px;
    bottom: 430px;
  }

  .section__shape-red {
    width: 37rem;
    left: 50px;
    bottom: 130px;
  }

  .section__shape-big-blue {
    left: 60px;
    top: 300px;
    width: 49rem;
  }

  .section__author {
    font-size: 2rem;
    line-height: 1.4;
  }

  .section__author-img {
    width: 42rem;
  }

  .section__author--artur {
    width: 50rem;
  }

  .section__small-text {
    padding-left: 150px;
  }

  .section__certified-img {
    width: 11rem;
    position: absolute;
    left: 190px;
  }

  .section--only-img {
    padding: 0 0 0 10rem;
  }

  .section__img-coffe {
    width: 47.7rem;
    right: 0;
    top: -338px;
  }

  .section__img-hand-wrapper {
    img {
      max-width: 37rem;
      width: 37rem;
    }

    &:after {
      left: 150px;
      top: 50px;
      width: 34rem;
    }
  }

  .quote {
    position: relative;

    &:before {
      width: 2rem;
      height: 2rem;
      left: -120px;
    }
  }
}

@media (min-width: 1400px) {
  .quote {
    &:before {
      left: -320px;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .section__title {
    top: 35px;
  }

  .section__certified-img {
    left: 100px;
  }

  blockquote {
    &:before {
      left: 60px;
    }
  }

  .section__img-coffe {
    width: 37.7rem;
    top: -275px;
  }
}


@include tablet() {
  .section--third {
    padding-bottom: 2rem;
  }

  .section--second {
    padding-bottom: 10rem;
    margin-bottom: 0;
  }

  .section__woman-with-phone {
    width: 23rem;
  }

  .section__desc {
    width: 50rem;
    margin: 0 auto;
  }

  .section__author {
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .section__certified {
    .section__small-text {
      padding-left: 4rem;
    }
  }

  .section__certified-img {
    width: 8rem;
    margin-left: -6rem;
    margin-right: 0;
  }

  .section__download-report {
    font-size: 2.6rem;

    &:before {
      width: 38rem;
      height: 35rem;
      left: 50%;
    }
  }

  .shape__shape-yellow {
    left: 45px;
    width: 30rem;
  }

  .section__shape-blue {
    top: 200px;
    left: 95px;
    width: 17rem;
  }

  .section__small-text {
    padding-left: 5rem;
    font-size: 1.3rem;
    max-width: 40rem;
    margin: 0 auto;
  }

  .section--only-img {
    padding: 0;
  }

  .section__img-coffe {
    width: 25rem;
    right: -55px;
  }

  .quote {
    &:before {
      left: -120px;
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  .section__download-report {
    > a {
      font-size: 2.6rem;
    }
  }
}

@include mobile() {
  .section__download-report {
    position: relative;

    > a {
      position: absolute;
      left: -90px;
      width: 250px;
    }
  }
}

//iphone 5 fix
@media screen and (device-aspect-ratio: 40/71) {
  .section__title--third,
  .section__title--second {
    top: 20px;
  }

  .section__woman-with-phone {
    width: 13rem;
  }
}
