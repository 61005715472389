.footer {
  font-family: $font-acumin-Black;
  color: $plum;
  font-size: 1rem;
  position: absolute;
  bottom: 20px;
  width: 100%;
  z-index: 2;

  > .container {
    margin: 0 auto;
  }
}

.footer__copyright {
  position: absolute;
  font-family: inherit;
  font-size: inherit;
  transform: rotate(-90deg);
  bottom: 60px;
  right: -40px;
}

.footer__check-reports {
  font-family: inherit;
  font-size: inherit;

  > a {
    font-size: inherit;
  }
}

.footer__row {
  @include flex(flex-start, center);
}


@include desktop() {
  .footer__copyright {
    padding-right: 30rem;
    font-size: 1.5rem;
  }

  .footer__check-reports {
    font-size: 1.5rem;
  }
}

@include desktop-big() {
  .footer__copyright {
    padding-right: 36rem;
  }
}

@include tablet() {
  .footer__copyright {
    padding-right: 10rem;
  }
}

@include tablet-and-desktop() {
  .footer__copyright {
    position: static;
    transform: none;
  }
}
