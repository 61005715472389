.header {
  position: relative;
  width: 100%;
  background: $yellow url("../images/gory.png") no-repeat bottom center;
  background-size: 1000px;
  overflow: hidden;

  .container {
    width: 100%;
    margin: 0 auto;
  }
}

.header__content {
  @include flex(space-between, center);
  flex-direction: column;
  height: 100vh;
  min-height: 450px;
  padding-top: 2rem;
}

.header__nav {
  @include flex(space-between, flex-start);
  width: 100%;
  z-index: 2;
}

.header__logo {
  width: 60pt;
}

.header__lang {
  width: 14pt;
  height: 14pt;
  cursor: pointer;

  &.hide {
    display: none;
  }
}

.header__title {
  font-weight: 700;
  color: $white;
  text-align: center;
  letter-spacing: 1px;
  height: 60vh;
  z-index: 1;
}

.header__bottom {
  position: relative;
  width: 100%;
  padding-bottom: 2rem;
  z-index: 1;
}

.header__scroll {
  position: absolute;
  bottom: 50px;
  left: -40px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 1rem;
  font-family: 'Acumin-Pro-Black', sans-serif;
  font-weight: bold;
  color: $white;
  transform: rotate(-90deg);
  display: flex;

  span {
    display: block;
    margin: 0 0 0 2rem;
    font-size: inherit;
  }

  .arrow {
    animation: mover 0.6s infinite alternate;
  }
}

@keyframes mover {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-5px);
  }
}

.header__desc {
  color: #fff;
  font-family: $font-acumin-Medium;
  text-align: center;
}

.header__shapes {
  position: absolute;
  bottom: -5px;
  left: 0;
  max-width: 100%;
}


@include tablet-and-desktop() {
  .header__nav {
    padding-top: 4.5rem;
  }

  .header__title {
    position: absolute;
    height: auto;
  }

  .header__content {
    padding: 0;
  }

  .header__desc {
    line-height: 1.5;
  }

  .header__scroll {
    left: -40px;
  }
}

@include tablet() {
  .header {
    height: 100vh;
    background-size: 1300px;
  }

  .header__logo {
    width: 11rem;
  }

  .header__lang {
    width: 2.4rem;
    height: 2.4rem;
  }

  .header__title {
    font-size: 3.8rem;
    top: 35px;
  }

  .header__scroll {
    font-size: 1.3rem;
  }

  .header__desc {
    font-size: 1.4rem;
  }
}

@include desktop() {
  .header {
    height: 105vh;
    background-size: 1420px;
  }

  .header__title {
    top: 25px;
  }

  .header__logo {
    width: 13rem;
  }

  .header__lang {
    width: 2.8rem;
    height: 2.8rem;
  }

  .header__desc {
    font-size: 2.3rem;
  }

  .header__bottom {
    padding-bottom: 0;
  }

  .header__scroll {
    font-size: 1.5rem;
    bottom: 50px;
    letter-spacing: 4px;
  }
}
