@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins'), url('../fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('PT Serif'), url('../fonts/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Acumin-Pro-Book';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin-Pro-Book.otf'), url('../fonts/Acumin-Pro-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Acumin-Pro-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin-Pro-Medium.otf'), url('../fonts/Acumin-Pro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Acumin-Pro-Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin-Pro-Semibold.otf'), url('../fonts/Acumin-Pro-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Acumin-Pro-Black';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin-Pro-Black.otf'), url('../fonts/Acumin-Pro-Black.otf') format('opentype');
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 400;
  line-height: 1.4;
  font-family: $font-ptSerif;

  > a {
    font: inherit;
    line-height: inherit;
    color: inherit;
  }

  > span {
    font: inherit;
  }
}


h1, .h1 {
  font-size: 5.4rem;
}

h2, .h2 {
  font-size: 3.8rem;
}

h3, .h3 {
  font-size: 3.4rem;
}

h4, .h4 {
  font-size: 2.2rem;
}

h5, .h5 {
  font-size: 1.8rem;
}

a {
  position: relative;
  color: $turquoise;
  transition: 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
}

small {
  font-size: 1rem;
  line-height: 1;

  > a {
    font-size: inherit
  }
}

p {
  font-family: 'Acumin-Pro-Book', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.1rem;
  line-height: 1.6;
}

.fw-bold {
  font-weight: 700;
}

.fw-medium {
  font-weight: 500;
}

.fw-thin {
  font-weight: 300;
}

.ta-left {
  text-align: left !important;
}

.ta-center {
  text-align: center !important;
}

.ta-right {
  text-align: right !important;
}

@include tablet() {
  h1, .h1 {
    font-size: 3rem;
  }

  h2, .h2 {
    font-size: 2.5rem;
  }

  span, p, a {
    font-size: 1.65rem;
  }
}

@include mobile() {
  h1, .h1 {
    font-size: 3.4rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3 {
    font-size: 1.3rem;
  }

  h4, .h4 {
    font-size: 1.2rem;
  }

  h5, .h5 {
    font-size: 1rem;
  }

  h6, .h6 {
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    line-height: 1.25;
  }

  span, p, a {
    font-size: 1.5rem;
    line-height: 1.45;
  }
}

@include tablet() {
  p {
    font-size: 2.1rem;
  }
}
