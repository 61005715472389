@import 'mixins';

$tablet-width: 768px;
$desktop-width: 1025px;
$big-desktop-width: 1600px;

$animate: all 0.2s ease-in-out;

$font-acumin: 'acumin-pro', sans-serif;
$font-acumin-Medium: 'Acumin-Pro-Medium', sans-serif;
$font-acumin-Semibold: 'Acumin-Pro-Semibold', sans-serif;
$font-acumin-Black: 'Acumin-Pro-Black', sans-serif;

$font-ptSerif: 'PT Serif', sans-serif;
$font-Poppins: 'Poppins', sans-serif;

$yellow: #FFC533;
$white: #FFFFFF;
$cyan: #60C3B4;
$orange: #FF643C;
$plum: #591245;
$azure: #B3DCE6;
$light-grey: #F7F7F7;
$dark-grey: #00000029;
$pink: #E94153;
$black: #000000;
$turquoise: #28A6B8;
